<template>
	<div class='c-card-list' :class='{"c-card-list-large": isLarge}'>
		<div class='c-card-list-item' v-for='(item, i) in items' :key='i'>
			<p class='a-text-with-icon'>
				<v-icon :icon='item.icon' size='15' />
				<span>{{item.name}}</span>
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			items: {
				type: Array
			},
			isLarge: {
				type: Boolean
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.c-card {
		&-list {
			display: flex;
			flex-wrap: wrap;
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			&-large {
				.a-text-with-icon {
					font-size: 18px;
					font-weight: 700;
				}
			}
			&-item {
				margin-right: 20px;
				.a-text-with-icon {
					color: rgba($black, .8);
				}
				.app-icon {
					color: rgba($black, .5);
				}
				span {
					hyphens: auto;
				}
			}
		}
	}
</style>
