<template>
	<div class="a-timeline-transfers">
		<div class='a-timeline-item'>
			<div class='a-timeline-transfers-wrap'>
				<div class='a-timeline-transfers-content'>
					<p class='a-timeline-transfers-title'>{{item.name}}</p>
					<p class='a-timeline-transfers-direction'>{{contract.PickupLocation.Name}} • {{contract.DestinationLocation.Name}}</p>
				</div>
				<a-card-list
					:items="[{icon: 'users', name: searchData.adults + searchData.children.length}]"
					isLarge
					style='margin-top: 5px'
				/>
			</div>
			<div class="divider mb-4"></div>
			<div class='a-timeline-transfers-footer'>
				<div class='row'>
					<div class='col-auto'>
						<p class='a-timeline-transfers-descr'>{{$t('account.form.placeholder.dateFrom')}}</p>
						<p class='a-timeline-transfers-text'>{{$moment(searchData.dateFrom).format("ddd, DD MMM YYYY")}}</p>
					</div>
					<div class='col-auto'>
						<p class='a-timeline-transfers-descr'>{{$t('account.form.placeholder.dateTo')}}</p>
						<p class='a-timeline-transfers-text'>{{$moment(searchData.dateTo).format("ddd, DD MMM YYYY")}}</p>
					</div>
				</div>
			</div>
		</div>
		<a-timeline-markup
			:item='item'
			:price="$helpers.amount(item.price, item.currency)"
			:result='true'
			:pdf='true'
		/>
	</div>
</template>

<script>
	import ATimelineMarkup from '@/views/account/itinerary/markup';
	import ACardList from '@/views/account/search/cars/card/list';

	export default {
		props: ['item', 'contract', 'searchData'],
		components: {
			ATimelineMarkup,
			ACardList
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline-transfers {
		&-wrap {
			padding: 20px 15px;
			@include minw($grid-breakpoints-xs) {
				padding: 25px;
			}
		}
		&-title,
		&-direction {
			font-size: 18px;
			font-weight: 400;
		}
		&-title {
			font-weight: 700;
			margin-bottom: 5px;
			@include minw($grid-breakpoints-xs) {
				margin-bottom: 10px;
			}
		}
		&-direction {
			@include maxw($grid-breakpoints-xs - 1) {
				font-size: 14px;
			}
		}
		&-footer {
			padding: 0 20px 15px;
			@include minw($grid-breakpoints-xs) {
				padding: 0 25px 25px;
			}
		}
		&-descr {
			font-size: 10px;
			font-weight: 400;
			line-height: 1.5;
			color: rgba($black, .6);
		}
		&-text {
			font-size: 12px;
			font-weight: 400;
			line-height: 1.5;
			text-transform: capitalize;
		}
	}
</style>
