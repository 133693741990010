<template>
	<div class='a-card' :class='{"onlyRead": onlyRead}'>
		<div class='t-card-wrap'>
			<div class='t-card-container'>
				<div class='t-card-image'>
					<img :src="item.image">
				</div>
				<div class='t-card-content'>
					<p class='t-card-title'>{{item.name}}</p>
					<ul class='a-list t-card-list'>
						<li class='a-list-item'>
							<v-icon icon='clock' size='15' class='a-list-icon' />
							{{$t('account.search.transfers.maximumWaitingTimeSupplierDomestic')}}: {{item.maximumWaitingTimeSupplierDomestic}}
						</li>
						<li class='a-list-item'>
							<v-icon icon='clock' size='15' class='a-list-icon' />
							{{$t('account.search.transfers.maximumWaitingTimeSupplierInternational')}}: {{item.maximumWaitingTimeSupplierInternational}}
						</li>
					</ul>
					<ul class='a-list default t-card-list'>
						<li class='a-list-item' v-for='(point, i) in item.leftBulletPoints' :key='i'>{{point}}</li>
					</ul>

					<div class='t-card-footer'>
						<div class='tablet-show' style='padding-right: 12px'>
							<v-button block xxsmall outline color='green' class='t-card-mobile-btn' :class='{"app-btn-active": comparison}'
								@click.stop="comparison ? deleteComparison() : addComparison()"
								:loading='loading_comparison'
							>
								<v-icon :icon='comparison ? "delete" : "graph"' size='14' class='desktop-show' />
								<span class='desktop-show'>{{comparison ? $t('account.buttons.remove') : $t('account.comparison.for_comparison')}}</span>
								<v-icon icon='delete' size='14' class='desktop-hide' v-if='comparison' />
								<span class='desktop-hide'>{{comparison ? $t('account.comparison.remove_from_comparison') : $t('account.comparison.add_to_compare')}}</span>
							</v-button>
						</div>
						<div class='ml-auto' style='flex-shrink: 0'>
							<span class='app-link green--text' @click='details = true'>{{$t('account.buttons.read_more')}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class='t-card-action'>
				<div class='t-card-action-total'>
					<!-- <p class='a-card-text'>{{$t('account.search.flights.from')}}</p> -->
					<p class='a-card-total'>{{$helpers.amount(item.price, item.currency)}}</p>
				</div>
				<div class='t-card-action-group-buttons'>
					<v-button block xxsmall color='green white--text'
						@click.stop='$emit("open-transfer")'
					>{{$t('account.buttons.choose')}}</v-button>

					<v-button block xxsmall outline color='green-persian' class='tablet-hide t-card-button-compare' :class='{"app-btn-active": comparison}'
						@click.stop="comparison ? deleteComparison() : addComparison()"
						:loading='loading_comparison'
					>
						<v-icon icon='delete' size='14' v-if='comparison' />
						{{comparison ? $t('account.comparison.remove_from_comparison') : $t('account.comparison.add_to_compare')}}
					</v-button>
				</div>
			</div>
		</div>

		<trips-details
			v-model='details'
			:details='item.rightBulletPoints'
			@close-dialog='details = false'
		/>

	</div>
</template>

<script>
	import TripsDetails from '@/views/account/details/transfers';
	import { addComparison, deleteComparison } from '@/services/request';

	export default {
		data: () => ({
			details: false,
			loading_comparison: false
		}),
		props: ["item", "check_availability", "onlyRead"],
		computed: {
			comparison() {
				if(!(this.check_availability && this.check_availability.items)) return;
				return Object.values(this.check_availability.items).some(el => el.code == this.item.code)
			}
		},
		components: {
			TripsDetails
		},
		methods: {
			addComparison() {
				if (this.check_availability.count >= this.check_availability.maxCount) {
					this.$store.commit('notification/errorMessage', {status: 'warning', title: this.$t('account.comparison.it_is_impossible.flight')})
				} else {
					this.loading_comparison = true;

					addComparison({
						type: 'transfer',
						code: this.item.code,
						orderId: this.$route.params.orderId,
						storageId: this.$route.params.storageId
					}).then((res) => this.$emit("update-comparison", res.data))
						.finally(() => this.loading_comparison = false);
				}
			},
			deleteComparison() {
				this.loading_comparison = true;

				deleteComparison({
					comparisonId: this.check_availability.id,
					code: this.item.code,
				}).then((res) => this.$emit("update-comparison", res.data))
					.finally(() => this.loading_comparison = false);
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.t-card {
		&-wrap {
			@include minw($grid-breakpoints-sm) {
				display: flex;
				align-items: stretch;
			}
		}
		&-container {
			flex: 1 1 100%;
			@include minw($grid-breakpoints-xs) {
				display: flex;
				align-items: center;
			}
		}
		&-image {
			flex-shrink: 0;
			width: 100%;
			height: 265px;
			padding: 20px 15px 0;
			@include minw($grid-breakpoints-xs) {
				padding: 0;
				width: 285px * .8;
				height: 268px * .8;
				margin: 20px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				width: 285px;
				height: 268px;
				margin: 20px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		&-content {
			padding: 15px;
			flex: 1 1 100%;
			margin: 0;
			display: flex;
			flex-direction: column;
			@include minw($grid-breakpoints-xs) {
				padding: 0;
				margin: 25px * .8 30px * .8 35px * .8 15px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				margin: 25px 30px 35px 15px;
			}
		}
		&-title {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 15px;
		}
		&-list {
			max-width: 350px;
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
		&-action {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px;
			border-top: 1px solid rgba($black, .1);
			@include minw($grid-breakpoints-sm) {
				flex-direction: column;
				align-items: stretch;
				justify-content: flex-end;
				border-top: none;
				border-left: 1px solid rgba($black, .1);
				padding: 20px;
				flex: 0 0 300px * .85;
			}
			@include minw($grid-breakpoints-md) {
				flex: 0 0 300px * .9;
			}
			@include minw($grid-breakpoints-lg) {
				flex: 0 0 300px;
			}
			&-total {
				display: grid;
				@include minw($grid-breakpoints-sm) {
					text-align: center;
					margin-bottom: 30px * .8;
				}
				@include minw($grid-breakpoints-lg) {
					margin-bottom: 30px;
				}
			}
			&-group-buttons {
				width: 100%;
				@include maxw($grid-breakpoints-sm - 1) {
					max-width: 125px;
				}
			}
			.app-btn {
				font-size: 15px;
				padding-left: 12px;
				padding-right: 12px;
				@include minw($grid-breakpoints-sm) {
					padding: 0 4px;
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
				@include minw($grid-breakpoints-lg) {
					font-size: 16px;
				}
				@include maxw($grid-breakpoints-sm - 1) {
					width: auto !important;
					min-width: 125px !important;
				}
				&-active {
					@include minw($grid-breakpoints-sm) {
						border: none;
					}
				}
			}
		}
		&-button {
			&-compare {
				font-weight: 400;
			}
		}
		&-footer {
			display: flex;
			align-items: center;
			@include maxw($grid-breakpoints-md - 1) {
				flex-wrap: wrap;
			}
		}
		&-mobile-btn {
			height: 40px;
			font-size: 14px;
			min-width: 125px;
		}
	}
</style>
