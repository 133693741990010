<template>
	<div v-if='price'>
		<div class='a-timeline-wrap-markup'>
			<template v-if='result'>
				<div class='a-timeline-wrap-markup-content result'>
					<p class='a-timeline-wrap-markup-text'>{{$t("account.itinerary.price_service")}}: </p>
					<p class='a-timeline-wrap-markup-title'>{{!pdf ? $helpers.amount(price.total.amount, price.total.currency) : price}}</p>
				</div>
			</template>
			<template v-else>
				<div class='a-timeline-wrap-markup-content'>
					<p class='a-timeline-wrap-markup-title'>{{$helpers.amount(price.basePrice.amount, price.basePrice.currency)}}</p>
					<p class='a-timeline-wrap-markup-text'>{{$t("account.itinerary.markup")}} {{$helpers.amount(price.userMargin.amount, price.userMargin.currency)}}</p>
				</div>
				<div class='a-timeline-wrap-markup-action'>
					<div class="a-timeline-button-add" @click='dialog = true'>{{$t("account.buttons.edit_price")}}</div>
				</div>
			</template>
		</div>

		<a-dialog-markup
			:orderId='$route.params.orderId'
			:item='item'
			:title='title'
			:titleIcon='titleIcon'
			v-model='dialog'
			:price='price'
			@update-order='$emit("update-order", $event)'
			@close-dialog="dialog = false"
			v-if='!pdf' />
	</div>
</template>

<script>
	import ADialogMarkup from '@/views/account/itinerary/dialogs/markup';

	export default {
		data: () => ({
			dialog: false
		}),
		props: ['item', 'price', 'title', 'titleIcon', 'result', 'pdf'],
		components: {
			ADialogMarkup
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline-wrap {
		&-markup {
			margin-top: 15px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				justify-content: space-between;
				align-items: center;
			}
			&-content {
				display: flex;
				flex-wrap: wrap;
				align-items: baseline;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					flex-direction: column;
					&.result {
						width: 100%;
						flex-direction: row;
						justify-content: space-between;
						@include maxw( $grid-breakpoints-xs - 1 ) {
							.a-timeline-wrap-markup-title {
								font-size: 15px;
							}
							.a-timeline-wrap-markup-text {
								font-size: 14px;
							}
						}
					}
				}
			}
			&-title {
				font-size: 13px;
				font-weight: 400;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 16px;
				}
			}
			&-text {
				font-size: 12px;
				font-weight: 400;
				color: rgba($black, .5);
				@include minw( $grid-breakpoints-xs ) {
					font-size: 13px;
					margin-left: 5px;
					margin-right: 5px;
				}
			}
			&-action {
				margin-left: 15px;
				flex-shrink: 0;
			}
		}
	}
</style>
