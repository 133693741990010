<template>
	<v-dialog :value='value' :width='1010' class-content='border-radius a-trip-dialog-share-wrap' @close-dialog="$emit('close-dialog')">
		<div class='a-trip-dialog-share-content'>
			<p class='a-trip-dialog-share-title'>{{$t("account.buttons.share")}}</p>

			<div class='a-trip-dialog-share-box'>
				<ADialogShareSend />
			</div>

		</div>
	</v-dialog>
</template>

<script>
	import ADialogShareSend from '@/views/account/comparison/components/send';

	export default {
		props: ['value'],
		components: {
			ADialogShareSend
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trip-dialog-share {
		&-wrap {
			padding: 0 !important;
		}
		&-content {
			padding: 40px 10px 100px;
			@include minw( $grid-breakpoints-xs ) {
				padding-left: 20px;
				padding-right: 20px;
			}
			@include minw( $grid-breakpoints-sm ) {
				padding: 35px 45px 75px;
			}
		}
		&-title {
			font-size: 22px;
			font-weight: 700;
			margin-bottom: 40px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-size: 20px;
				font-weight: 400;
				margin-bottom: 25px;
			}
		}
		&-list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 15px;
			color: $green-persian;
			&-item {
				display: flex;
				align-items: center;
				margin-bottom: 15px;
				cursor: pointer;
				&-text {
					font-size: 12px;
					font-weight: 700;
					margin-left: 5px;
				}
				.app-icon {
					flex-shrink: 0;
					&.loading {
						animation: rotating .6s linear infinite;
						svg {
							fill: none;
							path {
								stroke: currentColor;
								stroke-width: 2px;
							}
							circle {
								stroke: currentColor;
								stroke-width: 2px;
								stroke-opacity: .25;
							}
						}
					}
				}
				&:not(:last-child) {
					margin-right: 24px;
					@include minw( $grid-breakpoints-xs ) {
						margin-right: 35px;
					}
				}
				&.active {
					color: rgba($black, .6);
					cursor: default;
				}
			}
		}
		&-box {
			box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
		}
	}

	@keyframes rotating {
		from { transform: rotate(0deg); }
		to { transform: rotate(360deg); }
	}
</style>
