<template>
	<div class='a-trip-dialog-share-send'>
		<ValidationObserver ref="form" v-slot="{ invalid }">
			<form class='feedback-form' @submit.prevent='onSubmit'>
				<div class="row">
					<div class="col-6">
						<ValidationProvider :name="$t('account.form.label.recipients_email')" rules="required|email" v-slot='{errors}'>
							<v-text-field
								:label="$t('account.form.label.recipients_email')"
								placeholder="email@email.com"
								name='emailRecipient'
								type='email'
								v-model='form.emailRecipient'
								:errorMessage='errors[0]'
							/>
						</ValidationProvider>
					</div>
					<div class="col-6">
						<ValidationProvider :name="$t('account.form.label.senders_email')" rules="required|email" v-slot='{errors}'>
							<v-text-field
								:label="$t('account.form.label.senders_email')"
								placeholder="email@email.com"
								name='emailSender'
								type='email'
								v-model='form.emailSender'
								:errorMessage='errors[0]'
							/>
						</ValidationProvider>
					</div>
					<div class="col-12">
						<ValidationProvider :name="$t('account.form.label.topic')" rules="required" v-slot='{errors}'>
							<v-text-field
								:label="$t('account.form.label.topic')"
								:placeholder="$t('account.form.placeholder.list_topic')"
								name='subject'
								v-model='form.subject'
								:errorMessage='errors[0]'
							/>
						</ValidationProvider>
					</div>
					<div class="col-12">
						<ValidationProvider :name="$t('account.form.label.additional_text')" rules="required" v-slot='{errors}'>
							<v-text-field
								:label="$t('account.form.label.additional_text')"
								:placeholder="$t('account.form.placeholder.your_text')"
								name='text'
								type='textarea'
								v-model='form.text'
								:errorMessage='errors[0]'
								:rows='5'
							/>
						</ValidationProvider>
					</div>
				</div>
				<div class="a-trip-dialog-share-send-group-buttons">
					<div class="a-trip-dialog-share-send-button">
						<v-button block xxsmall color='green-persian white--text' :disabled="invalid" @click='comparisonSend' :loading='loading_btn'>{{$t("account.buttons.send")}}</v-button>
					</div>
				</div>
			</form>
		</ValidationObserver>

		<dialog-success
			:title="$t('account.messages.success.your_request_has_been_sent_successfully')"
			v-model='success'
			@close-dialog="success = false"
		/>
	</div>
</template>

<script>
	import DialogSuccess from '@/views/account/home/components/DialogSuccess';
	import { comparisonSend } from '@/services/request';

	export default {
		data: () => ({
			form: {
				emailRecipient: '',
				emailSender: '',
				subject: '',
				text: ''
			},
			loading_btn: false,
			success: false
		}),
		components: {
			DialogSuccess
		},
		methods: {
			comparisonSend() {
				this.errors = {};
				this.loading_btn = true;

				comparisonSend(this.$route.params.comparisonId, this.form).then(() => {
					this.success = true;
					this.clearForm();
				})
				.catch(err => this.errors = err.response.data?.errors ?? {})
				.finally(() => this.loading_btn = false);
			},
			clearForm() {
				this.form = {
					emailRecipient: '',
					emailSender: '',
					subject: '',
					text: ''
				},
				this.errors = {};

				this.$nextTick(() => this.$refs.form.reset());
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trip-dialog-share-send {
		padding: 20px 10px 30px;
		@include minw( $grid-breakpoints-xs ) {
			padding-left: 20px;
			padding-right: 20px;
		}
		@include minw( $grid-breakpoints-sm ) {
			padding: 35px 40px 30px;
		}
		&-group-buttons {
			@include minw( $grid-breakpoints-xs ) {
				padding-top: 8px;
				justify-content: flex-end;
				display: flex;
				.app-btn {
					min-width: 132px !important;
				}
			}
		}
	}
</style>
