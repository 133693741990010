<template>
	<v-dialog :value='value' :showCloseCross='false' :width='479' class='a-transfers-details-dialog' class-content='border-radius' @close-dialog="closeDialog">
		<div class='a-card-details-header'>
			<div class="row align-items-center">
				<div class="col">
					<p class='a-card-details-header-title'>{{$t('account.details.transfers.title')}}</p>
				</div>
				<div class="col-auto">
					<div class='a-card-details-header-button' @click.stop='closeDialog'>
						<v-icon icon='cross' size='17' />
					</div>
				</div>
			</div>
		</div>
		<div class='a-transfers-details-dialog-content'>
			<ul class='a-list default'>
				<li class='a-list-item' v-for='(point, i) in details' :key='i'>{{point}}</li>
			</ul>
		</div>
		<div class='a-card-details-footer'>
			<div class='a-card-details-footer__divider'>
				<div class='divider' />
			</div>
			<div class="row align-items-center justify-items-center">
				<div class="col-auto">
					<v-button xxsmall color='green white--text' class='a-card-details-footer-button'
						@click='closeDialog()'
					>{{$t('account.buttons.close')}}</v-button>
				</div>
			</div>
		</div>
	</v-dialog>
</template>

<script>
	export default {
		props: ['value', 'details'],
		methods: {
			closeDialog() {
				this.$emit('close-dialog');
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-transfers-details-dialog {
		.app-popup {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				overflow-y: auto;
			}
			&__content {
				padding: 0;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					max-width: 100% !important;
				}
			}
		}
		.a-card-details {
			&-header {
				height: 70px;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					height: 92px;
					padding-top: 20px;
					padding-bottom: 20px;
					.row {
						flex-direction: column-reverse;
						align-items: normal;
					}
					.col {
						display: grid;
						justify-content: center;
					}
					.col-auto {
						margin-left: auto;
					}
				}
				&-title {
					font-size: 18px;
					@include maxw( $grid-breakpoints-xs - 1 ) {
						font-size: 20px;
						font-weight: 400;
					}
				}
			}
		}
		.a-list-item {
			line-height: 23px;
			&:before {
				margin-top: 8px !important;
				margin-bottom: 8px !important;
			}
		}
		&-content {
			padding: 15px;
			height: calc(100vh - 92px - 70px);
			overflow-x: hidden;
			overflow-y: auto;
			@include minw( $grid-breakpoints-xs ) {
				height: 240px;
				padding: 20px * .8 35px * .8 25px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 20px 35px 25px;
			}
		}
	}
</style>
