<template>
	<v-dialog :value='value' class-content='border-radius a-timeline-dialog' @close-dialog="$emit('close-dialog')">
		<div class='a-timeline-dialog-content'>
			<p class='a-timeline-dialog-title'>{{$t("account.itinerary.dialogs.price_editing")}}</p>
			<div class="a-timeline-dialog-direction">
				<div class="a-timeline-dialog-direction-icon">
					<v-icon :icon='titleIcon' size='21' />
				</div>
				<div>
					<p class="a-timeline-dialog-direction-title">{{title}}</p>
					<slot />
				</div>
			</div>
			<div class="a-timeline-dialog-form">
				<div class="a-timeline-dialog-form-col">
					<p class="a-timeline-dialog-form-label">{{$t("account.itinerary.dialogs.starting_price")}}</p>
					<div class="a-timeline-dialog-form-flex">
						<p class="a-timeline-dialog-form-price">{{$helpers.amount(price.basePrice.amount, price.basePrice.currency)}}</p>
						<span class="a-timeline-dialog-form-operator">+</span>
					</div>
				</div>
				<div class="a-timeline-dialog-form-col">
					<p class="a-timeline-dialog-form-label">{{$t("account.itinerary.markup")}}</p>
					<div class="a-timeline-dialog-form-flex">
						<input type="text" class="a-timeline-dialog-form-input" placeholder='0' v-model='markup'>
						<span class='a-timeline-dialog-form-currency'>{{$helpers.isoCurrencies($store.state.currency)}}</span>
						<span class="a-timeline-dialog-form-operator mobile-hide"><strong>=</strong></span>
					</div>
				</div>
				<div class="a-timeline-dialog-form-col a-timeline-dialog-form-totals">
					<p class="a-timeline-dialog-form-label">{{$t("account.itinerary.dialogs.total_price")}}</p>
					<p class="a-timeline-dialog-form-total">{{total}}</p>
				</div>
			</div>
			<div class='a-timeline-dialog-action'>
				<div class='a-timeline-dialog-col'>
					<v-button block xxsmall color='green white--text' @click="updateMarginTourists()"
						:loading='loading_btn'
					>{{$t("account.buttons.apply")}}</v-button>
				</div>
			</div>
		</div>
	</v-dialog>
</template>

<script>
	import { updateMarginTourists } from '@/services/request';

	export default {
		data: () => ({
			markup: '',
			loading_btn: false
		}),
		props: ['orderId', 'item', 'value', 'price', 'title', 'titleIcon'],
		created() {
			this.initialMarkup();
		},
		computed: {
			total() {
				return this.$helpers.amount(Number(this.onlyAmount(this.price.basePrice.amount)) + Number(this.markup), this.$store.state.currency);
			}
		},
		methods: {
			onlyAmount(amount) {
				if(this.$store.state.currency && this.$store.state.exchange_rates.length) {
					let initialExchange = this.$store.state.exchange_rates.find(el => el.code === this.$store.state.currency);
					let exchange = this.$store.state.exchange_rates.find(el => el.code === this.price.userMargin.currency);

					return (Number(initialExchange.rate) / Number(exchange.rate) * Number(amount)).toFixed(0);
				} else {
					return Number(amount).toFixed(0);
				}
			},
			onlyAmountRevert(amount) {
				if(this.$store.state.currency && this.$store.state.exchange_rates.length) {
					let initialExchange = this.$store.state.exchange_rates.find(el => el.code === this.$store.state.currency);
					let exchange = this.$store.state.exchange_rates.find(el => el.code === this.price.userMargin.currency);

					return (Number(exchange.rate) * Number(amount) / Number(initialExchange.rate)).toFixed(2);
				} else {
					return Number(amount).toFixed(2);
				}
			},
			initialMarkup() {
				this.markup = this.onlyAmount(this.price.userMargin.amount);
			},
			updateMarginTourists() {
				this.loading_btn = true;

				updateMarginTourists({
					orderId: this.orderId,
					data: {
						amount: this.onlyAmountRevert(Number(this.markup)),
						currency: this.price.basePrice.currency,
						type: this.item.type,
						id: this.item.id
					}
				}).then((res) => {
					this.$emit("update-order", res.data);
					this.$emit('close-dialog');
				})
				.finally(() => this.loading_btn = false)
			}
		},
		watch: {
			value() {
				this.initialMarkup();
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline-dialog {
		padding: 40px;
		padding-bottom: 30px;
		@include minw( $grid-breakpoints-xs ) {
			width: auto;
			min-width: 560px;
		}
		@include maxw( $grid-breakpoints-xs - 1 ) {
			max-width: 100% !important;
			border-radius: 0 !important;
			padding-left: 15px;
			padding-right: 15px;
		}
		&-title {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 30px;
		}
		&-direction {
			display: flex;
			margin-bottom: 30px;
			&-icon {
				flex-shrink: 0;
				color: $green-persian;
				margin-right: 8px;
			}
			&-title {
				font-size: 16px;
				font-weight: 700;
			}
		}
		&-form {
			display: flex;
			@include minw( $grid-breakpoints-xs ) {
				margin-bottom: 15px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex-wrap: wrap;
			}
			&-col {
				margin-bottom: 10px;
			}
			&-flex {
				display: flex;
				align-items: center;
			}
			&-label {
				font-size: 12px;
				font-weight: 400;
				color: rgba($black, .5);
				margin-bottom: 5px;
				margin-right: 15px;
			}
			&-price,
			&-operator,
			&-currency {
				font-size: 18px;
				line-height: 30px;
				font-weight: 400;
				white-space: nowrap;
			}
			&-operator {
				padding: 0 16px;
			}
			&-total {
				font-size: 22px;
				font-weight: 700;
			}
			&-totals {
				@include maxw( $grid-breakpoints-xs - 1 ) {
					width: 100%;
					text-align: center;
					padding-top: 20px;
					border-top: 1px solid rgba($black, .1);
					margin: 20px 0;
				}
			}
			&-input {
				width: 60px;
				font-size: 16px;
				font-weight: 400;
				padding: 4px 15px;
				border: 1px solid rgba($black, 0.1);
				border-radius: $border-radius;
				transition: border-color $transition;
				margin-right: 8px;
				&:hover {
					border-color: rgba($black, 0.3);
				}
				&::placeholder {
					opacity: 1;
					color: rgba($black, .4);
				}
				&:focus::placeholder {
					transform: translateX(-100%);
					transition: transform $transition;
				}
			}
		}
		&-action {
			@include minw( $grid-breakpoints-xs ) {
				display: flex;
				.app-btn {
					min-width: 140px !important;
				}
			}
		}
	}
</style>
