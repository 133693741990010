<template>
	<div class="col-auto">
		<div class='a-comparison-list mobile-hide'>
			<div class='a-comparison-list-item' v-tooltip.top="$t('account.comparison.copy_link')" @click='copyToClipboard'>
				<v-icon icon='copy' :size='23' />
			</div>
			<div class='a-comparison-list-item' v-tooltip.top="$t('account.comparison.download_pdf')" @click='generatePdf'>
				<v-icon class='loading' icon='loading' :size='23' v-if='loading_pdf' />
				<v-icon icon='download' :size='23' v-else />
			</div>
			<div class='a-comparison-list-item' v-tooltip.top="$t('account.comparison.send_email')" @click='dialog_share = true'>
				<v-icon icon='mail' :size='23' />
			</div>
		</div>
		<div class='a-comparison-list mobile-show'>
			<div class='a-comparison-list-item' @click='copyToClipboard'>
				<v-icon icon='copy' :size='23' />
				<span class='a-comparison-list-item-text'>{{$t('account.comparison.copy_link')}}</span>
			</div>
			<div class='a-comparison-list-item' @click='generatePdf'>
				<v-icon class='loading' icon='loading' :size='23' v-if='loading_pdf' />
				<v-icon icon='download' :size='23' v-else />
				<span class='a-comparison-list-item-text'>{{$t('account.comparison.download_pdf')}}</span>
			</div>
			<div class='a-comparison-list-item' @click='dialog_share = true'>
				<v-icon icon='mail' :size='23' />
				<span class='a-comparison-list-item-text'>{{$t('account.comparison.send_email')}}</span>
			</div>
		</div>

		<a-pdf
			ref='app-pdf'
			:filename='filename'
			@update-loading='$event => loading_pdf = $event'
		>
			<template v-slot:content>
				<slot name='share' />
			</template>
		</a-pdf>

		<a-dialog-share
			v-model='dialog_share'
			@close-dialog="dialog_share = false"
		/>
	</div>
</template>

<script>
	import { copyToClipboard } from '@/helpers/copyToClipboard';
	import APdf from '@/components/account/APdf';
	import ADialogShare from '@/views/account/comparison/components/share';

	export default {
		data: () => ({
			loading_pdf: false,
			dialog_share: false
		}),
		props: ["filename", "link"],
		components: {
			APdf,
			ADialogShare
		},
		methods: {
			copyToClipboard() {
				copyToClipboard(this.link);
			},
			generatePdf() {
				this.$refs["app-pdf"].$refs.html2Pdf.generatePdf();
			}
		}
	}
</script>
